import React from "react"

import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import { Helmet} from "react-helmet";
import Layout from "../components/layout/Layout"
import JsonData from "../data/politicaCookies.json"
import * as style from "../styles/cookies.module.css"


const PoliticaCookies = () => {

    const { t } = useTranslation()

    const Table = ({ key, content}) => {
        return (
            <table className={style.darkTable} key={key}>
                {content.map((data, index) => (
                    <tr key={index}>
                        {data.row.map((cont, index) => {
                            if (cont.text === "title") return ( <td key={index}>{t(`Cookies.${cont.celda}`)}</td> )
                            else if (cont.text === "text") return ( <th key={index}>{t(`Cookies.${cont.celda}`)}</th> )
                            else return (<></>)
                        })}
                    </tr>
                ))}
            </table>
        )
    }
    return (
        <Layout href="../" link="./">
            {JsonData.map((data, index) => {
                if (data.tag === "p") return (<div className={style.paragraph} key={index}>{t(`Cookies.${data.content}`)}</div>)
                else if (data.tag === "table") return (<Table key={index} content={data.content}/>)
                else if (data.tag === "h1") return (<h1 className={style.h1} key={index}>{t(`Cookies.${data.content}`)}</h1>)
                else return (<></>)
            })}
        </Layout>
    )
}

export default PoliticaCookies

export function Head() {
  
  return (
    <>
        <Helmet>
            <html lang='es'/>
            <title>GRUPO GOF</title>
            <meta name="description" content="Página desarrollada por Nortic" />
            <link rel="icon" href="https://grupogof.com/wp-content/uploads/2018/10/icono-300x300.png" type="image/png"></link>
        </Helmet>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;